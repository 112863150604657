import React, {useState} from 'react'
import {Input, Flex, Text, Image, useToast} from '@chakra-ui/core'
import Button from '../components/Button'
import useLogin from '../hooks/useLogin'
import {useHistory} from 'react-router-dom'

export default function Login() {
  const history = useHistory()
  const toast = useToast()
  const [credentials, setCredentials] = useState({})
  const [login, {status, data}] = useLogin()

  React.useEffect(() => {
    if (data?.success === false || status === 'error') {
      toast({
        description: data?.message ?? 'Something went wrong!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (data?.token) {
      localStorage.setItem('token', data.token)
      history.push('/')
    }
  }, [data, history, status, toast])

  return (
    <Flex flexDir="column" align="center" justify="center" height="100%">
      <Flex flexDir="row" align="center" justify="center" mb="2rem">
        <Text
          as="h2"
          fontSize="xl"
          fontFamily="Montserrat"
          fontWeight="bold"
          mr="1rem"
        >
          Welcome
        </Text>
        <Image
          src="https://raw.githubusercontent.com/iampavangandhi/iampavangandhi/master/gifs/Hi.gif"
          width="30px"
        />
      </Flex>
      <Input
        placeholder="Username"
        type="text"
        w="20rem"
        name="username"
        onChange={(event) =>
          setCredentials({
            ...credentials,
            username: event.target.value,
          })
        }
      />
      <Input
        placeholder="Password"
        type="password"
        name="password"
        w="20rem"
        mt="1rem"
        onChange={(event) =>
          setCredentials({
            ...credentials,
            password: event.target.value,
          })
        }
      />

      <Button
        mt="1rem"
        w="20rem"
        text="Sign in"
        onClick={() => login(credentials)}
        isLoading={status === 'loading'}
        loadingText="Signing in..."
      />
    </Flex>
  )
}
