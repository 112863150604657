import {useMutation} from 'react-query'

export default function useGetAnalytics() {
  const handleAnalytics = async ({slug}) => {
    console.log(slug)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
    const data = await fetch(`https://mvcrisis.com/url/${slug}`, requestOptions)
    return data.json()
  }

  const [mutate, {status, data}] = useMutation(handleAnalytics)

  const getAnalytics = async (slug) => {
    try {
      await mutate({slug})
    } catch (error) {
      console.log(error)
    }
  }

  return [getAnalytics, {status, data}]
}
