import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ThemeProvider, CSSReset } from "@chakra-ui/core"
import Home from "./pages/Home.jsx"
import Login from "./pages/Login.jsx"
import PrivateRoute from "./pages/PrivateRoute.jsx"
import customTheme from "./theme"

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App
