import React from "react"
import { Flex, Text, Box, Input, useToast } from "@chakra-ui/core"
import Button from "../components/Button"
import useGetAnalytics from "../hooks/useGetAnalytics"
import { isValidURL, normalizeURL, normalizeSlug } from "../utils"
import styled from "@emotion/styled"

export default function Analytics() {
  const toast = useToast()
  const [link, setLink] = React.useState("")
  const [getAnalytics, { status, data }] = useGetAnalytics()

  React.useEffect(() => {
    if (status === "error")
      toast({
        description: "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      })

    if (data?.success === false)
      toast({
        description: Array.isArray(data?.message)
          ? `${data?.message[0]?.msg} in ${data?.message[0]?.param}`
          : data?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
  }, [data, status, toast])

  const handleClick = () => {
    const NormalizedURL = normalizeURL(link)
    if (!link || !isValidURL(NormalizedURL)) {
      toast({
        description: "Invalid URL",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }
    getAnalytics(normalizeSlug(link))
  }
  return (
    <Box w={[, , , "990px"]} px="15px" mx="auto">
      <Text
        as="h2"
        fontWeight="medium"
        fontFamily="Montserrat"
        textAlign="center"
        my="20px"
        fontSize="24px"
      >
        Get to know your Links
      </Text>
      <Text
        color="#a0aec0"
        mt="1rem"
        mb="2rem"
        textAlign="center"
        fontFamily="Montserrat"
        fontWeight="normal"
      >
        Uncover the number of clicks and where they come from
      </Text>

      <Flex flexDir={["column", "column", "row", "row"]} mb="1rem">
        <Input
          w={["100%", "100%", "75%", "75%"]}
          height="50px"
          mr="50px"
          fontFamily="Montserrat"
          placeholder="https://mvcrisis.com/something"
          type="text"
          autocorrect="off"
          autocapitalize="none"
          onChange={(event) => setLink(event.target.value)}
        />
        <Button
          text="Find"
          onClick={handleClick}
          isLoading={status === "loading"}
          loadingText="Finding..."
        />
      </Flex>

      {data?.success && <AnalyticsMap data={data} />}
    </Box>
  )
}

const StyledMap = styled.div`
  #chartdiv {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    overflow: hidden;
  }

  .total {
    margin-bottom: 1rem;
    text-align: left;
  }
  .total-text {
    margin-right: 0.3rem;
    display: inline-block;
    color: #a0aec0;
  }

  .total-count {
    font-size: 18px;
    width: 18px;
    height: 22px;
  }
`

const AnalyticsMap = ({ data }) => {
  const chart = React.useRef(null)
  React.useLayoutEffect(() => {
    window.am4core.ready(function () {
      // Themes begin
      window.am4core.useTheme(window.am4themes_animated)
      // Themes end

      // Create map instance
      var chart = window.am4core.create("chartdiv", window.am4maps.MapChart)

      // Set map definition
      chart.geodata = window.am4geodata_worldLow

      // Set projection
      chart.projection = new window.am4maps.projections.Miller()

      // Create map polygon series
      var polygonSeries = chart.series.push(
        new window.am4maps.MapPolygonSeries()
      )

      // Exclude Antartica
      polygonSeries.exclude = ["AQ"]

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true

      // Configure series
      var polygonTemplate = polygonSeries.mapPolygons.template
      polygonTemplate.tooltipText = "{name}"
      polygonTemplate.polygon.fillOpacity = 0.6

      // Create hover state and set alternative fill color
      var hs = polygonTemplate.states.create("hover")
      hs.properties.fill = chart.colors.getIndex(0)

      // Add image series
      var imageSeries = chart.series.push(new window.am4maps.MapImageSeries())
      imageSeries.mapImages.template.propertyFields.longitude = "longitude"
      imageSeries.mapImages.template.propertyFields.latitude = "latitude"
      imageSeries.mapImages.template.tooltipText = "{title}"
      imageSeries.mapImages.template.propertyFields.url = "url"

      chart.seriesContainer.draggable = false
      chart.seriesContainer.resizable = false
      chart.maxZoomLevel = 1

      var circle = imageSeries.mapImages.template.createChild(
        window.am4core.Circle
      )
      circle.radius = 3
      circle.propertyFields.fill = "color"

      var circle2 = imageSeries.mapImages.template.createChild(
        window.am4core.Circle
      )
      circle2.radius = 3
      circle2.propertyFields.fill = "color"

      circle2.events.on("inited", function (event) {
        animateBullet(event.target)
      })

      function animateBullet(circle) {
        var animation = circle.animate(
          [
            { property: "scale", from: 1, to: 5 },
            { property: "opacity", from: 1, to: 0 },
          ],
          1000,
          window.am4core.ease.circleOut
        )
        animation.events.on("animationended", function (event) {
          animateBullet(event.target.object)
        })
      }

      var colorSet = new window.am4core.ColorSet()

      imageSeries.data = data?.message?.locations?.map((location) => ({
        title: location.country,
        latitude: Number(location.latitude),
        longitude: Number(location.longitude),
        color: colorSet.next(),
      }))
    })
  }, [data, data?.message?.locations])

  return (
    <StyledMap>
      <div className="map-wrapper">
        <div className="total">
          <span className="total-text">Number of Clicks:</span>
          <strong className="total-count">
            {data?.success ? data.message.count : "🤔"}
          </strong>
        </div>
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
      </div>
    </StyledMap>
  )
}
