import {useMutation} from 'react-query'

export default function useLogin() {
  const handleLogin = async ({credentials}) => {
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    }
    const data = await fetch('https://mvcrisis.com/user/auth', requestOptions)
    return data.json()
  }

  const [mutate, {status, data}] = useMutation(handleLogin)

  const login = async (credentials) => {
    try {
      await mutate({credentials})
    } catch (error) {
      console.log(error)
    }
  }

  return [login, {status, data}]
}
