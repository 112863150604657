import jwt from "jsonwebtoken"

const useVerifyToken = () => {
  let token = localStorage.getItem("token")
  if (token) {
    jwt.verify(token, process.env.REACT_APP_JWT_SECRET, (err, decoded) => {
      if (err ?? decoded.exp < Date.now() / 1000) {
        localStorage.removeItem("token")
        token = null
      }
      token = true
    })
  }
  return token
}

export default useVerifyToken
