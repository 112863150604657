import { useMutation } from "react-query"

export default function useShorten() {
  const handleShortening = async ({ link }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        url: link.url,
        slug: link.slug,
      }),
    }
    const data = await fetch("https://mvcrisis.com/url", requestOptions)
    return data.json()
  }

  const [mutate, { status, data }] = useMutation(handleShortening)

  const shorten = async (link) => {
    try {
      await mutate({ link })
    } catch (error) {
      console.log(error)
    }
  }

  return [shorten, { status, data }]
}
