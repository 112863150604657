import React from 'react'
import {Button as ChakraButton} from '@chakra-ui/core'

export default function Button({text, ...props}) {
  return (
    <ChakraButton
      padding="18px 30px"
      w={['100%', '100%', '25%', '25%']}
      height="50px"
      bg="#5ca3d9"
      _hover={{bg: '#025590'}}
      _active={{bg: '#025590'}}
      color="white"
      fontFamily="Montserrat"
      mt={['1rem', '1rem', '0', '0']}
      {...props}
    >
      {text}
    </ChakraButton>
  )
}
