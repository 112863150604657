import React from 'react'
import {Flex, Text, Button} from '@chakra-ui/core'
import {useHistory} from 'react-router-dom'

export default function Nav() {
  const history = useHistory()
  return (
    <Flex
      align="center"
      justify="space-between"
      py="1rem"
      w={[, , , '990px']}
      px="15px"
      mx="auto"
    >
      <Text as="h3" fontWeight="bold" fontFamily="Montserrat" fontSize="lg">
        MvCrisis
      </Text>
      <Button
        fontWeight="normal"
        fontFamily="Montserrat"
        bg="transparent"
        onClick={() => {
          localStorage.removeItem('token')
          history.push('/login')
        }}
      >
        Logout
      </Button>
    </Flex>
  )
}
