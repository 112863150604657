import React from "react"
import Nav from "../components/Nav"
import { Box, Image } from "@chakra-ui/core"
import Shorten from "../components/Shorten"
import Analytics from "../components/Analytics"
import Banner from "./p6ZOrOr.png"

export default function Home() {
  return (
    <Box>
      <Nav />
      <Image
        size={["90%", "90%", "500px", "500px"]}
        height={["unset", "unset", "unset", "unset"]}
        borderRadius="8px"
        m={["2rem 1rem", "2rem 1rem", "2rem auto", "2rem auto"]}
        src={Banner}
        alt="logo"
      />
      <Shorten />
      <Analytics />
    </Box>
  )
}
