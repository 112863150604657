import React from "react"
import { useHistory } from "react-router-dom"

export default function useAuthListener() {
  const history = useHistory()
  React.useEffect(() => {
    const handler = ({ detail }) => {
      if (
        detail.status === 401 &&
        !/\/(logout|login)/.test(history.location.pathname)
      ) {
        localStorage.removeItem("token")
        history.push("/login")
      }
    }
    document.addEventListener("api:unauthorized", handler)
    return () => {
      document.removeEventListener("api:unauthorized", handler)
    }
  }, [history, history.location.pathname])
}
