import React from "react"
import { Box, Input, Flex, useToast, useClipboard, Link } from "@chakra-ui/core"
import Button from "../components/Button"
import useShorten from "../hooks/useShorten"
import { isValidURL, normalizeURL, isValidSlug } from "../utils"

export default function Shorten() {
  const toast = useToast()
  const [shorten, { status, data }] = useShorten()
  const [link, setLink] = React.useState({
    url: "",
    slug: "",
  })

  React.useEffect(() => {
    if (status === "error")
      toast({
        description: "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      })

    if (data?.success === false)
      toast({
        description: Array.isArray(data?.message)
          ? `${data?.message[0]?.msg} in ${data?.message[0]?.param}`
          : data?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
  }, [data, status, toast])

  const handleShorten = () => {
    const NormalizedURL = normalizeURL(link?.url)
    if (!link?.url || !isValidURL(NormalizedURL)) {
      toast({
        description: "Invalid URL",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }
    if (!isValidSlug(link?.slug)) {
      toast({
        description: "Slug has invalid characters.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }
    shorten({ ...link, url: NormalizedURL })
  }

  return (
    <Box w="100%" bg="#063d67" py="40px" my="3rem">
      <Flex
        w={[, , , "990px"]}
        px="15px"
        mx="auto"
        flexDir={["column", "column", "row", "row"]}
      >
        <Input
          w={["100%", "100%", "75%", "75%"]}
          height="50px"
          mr="50px"
          fontFamily="Montserrat"
          placeholder="http://some-long.link/that-you-do-not-care-about"
          type="text"
          autocorrect="off"
          autocapitalize="none"
          onChange={(event) =>
            setLink({
              ...link,
              url: event.target.value,
            })
          }
        />
        <Input
          w={["100%", "100%", "75%", "75%"]}
          height="50px"
          mr="50px"
          fontFamily="Montserrat"
          placeholder=":slug"
          type="text"
          mt={["1rem", "1rem", 0, 0]}
          autocorrect="off"
          autocapitalize="none"
          onChange={(event) =>
            setLink({
              ...link,
              slug: event.target.value,
            })
          }
        />
        <Button
          text="Shorten"
          onClick={handleShorten}
          isLoading={status === "loading"}
          loadingText="Shortening..."
        />
      </Flex>
      {data?.message?.slug && (
        <Clipboard url={data.message.url} slug={data.message.slug} />
      )}
    </Box>
  )
}

const Clipboard = ({ url, slug }) => {
  const { onCopy, hasCopied } = useClipboard(`https://mvcrisis.com/${slug}`)
  return (
    <Box w={[, , , "990px"]} px="15px" mx="auto" mt="1rem">
      <Flex
        w="100%"
        bg="white"
        align="center"
        justify="space-between"
        p="16px 20px"
        borderRadius="0.25rem"
        flexWrap
        flexDir={["column", "column", "row", "row"]}
      >
        <Box fontFamily="Montserrat">{url}</Box>
        <Flex
          align="center"
          justify="flex-end"
          flexDir={["column", "column", "row", "row"]}
        >
          <Link
            href={`https://mvcrisis.com/${slug}`}
            color="#2a5bd7"
            fontFamily="Montserrat"
            target="_blank"
            pr={[0, 0, "25px", "25px"]}
          >
            https://mvcrisis.com/{slug}
          </Link>
          <Button
            onClick={onCopy}
            fontFamily="Montserrat"
            text={hasCopied ? "Copied" : "Copy"}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
